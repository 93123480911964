import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Gaps Between Teeth – Cosmetic Dentist Toronto | The Smile Stylist" 
    description="Looking forward to fill the gaps between your teeth? Book your Free Consultation with The Smile Stylist, Toronto\'s Top Cosmetic Dentistry Clinic." 
    keywords="Cosmetic Dentist Toronto" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="gaps-between hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Gaps Between the Teeth</h1>
            <h2>Gaps in adult teeth are referred to as "diastemas"</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>
            <h2>
              <a href="/gaps-beetween-teeth">Gaps Between the Teeth - Diastemas</a>
            </h2>
            </p>
            <p className="lead-black-bold">Children's teeth develop gaps as they grow because their jaw is getting bigger and their baby teeth remain the same size. Children may have temporary gaps as their baby teeth start to fall out. Diastemas are more of an aesthetic concern for adults if it affects their smile or confidence.</p>
            <p>Gaps in adult teeth are referred to as diastemas. Diastemas appear most often between the two upper front teeth, though they can occur between any two teeth. Diastemas are also normal (take David Letterman, Mike Tyson or even supermodel Lauren Hutton, for example) and like gaps found in children, may result from a mismatch between jaw bone size and teeth size.</p>
            <p>If you are concerned about a gap in your teeth trust that the Smile Stylist can help! We get great results closing gaps between teeth and would love to custom design a <a href="/virtual-smile-makeover">virtual smile enhancement</a> with our complimentary online Smile Makeover tool</p>
            <h3>Other than normal sizing issues, there are three other reasons why gaps may form:</h3>
            <ul>
              <li>
                <strong> Tumor growths.</strong> A gap can be a sign of a serious medical or dental condition. Tumor growths may need to be treated surgically.
              </li>
              <li>
                <strong> Gum disease. </strong>Gum disease, which results in a loss of bone that supports the teeth, may be prevented by regular brushing and flossing. However, if a gap is forming as a result of gum disease, you need to see your dentist as soon as possible.
              </li>
              <li>
                <strong> Self-inflicted behavior.</strong> These gaps occur when people push their tongue against their front teeth when swallowing or as a result of mouth jewelry'. This pushes teeth forward and makes small gaps bigger. Again, if a small gap is getting bigger, its always a good idea to have it checked. We can help!
              </li>
            </ul>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 7.png"  )} alt="Before and After Teeth Gap Treatment" className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Gaps2.jpg" )} alt="Gaps Between Teeth Before and After " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Gaps.jpg" )} alt="Dental Gap Filling Before and After" className="img-responsive" />
            </p>


          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage